import { getAccessToken } from 'src/app/shared/auth-utils';
import { log } from 'src/app/shared/log';
import { environment } from 'src/environments/environment';
import { Datasource, DatasourceConfig } from './datasource';
import { DatasourceType } from './datasource-type';
import { DatasourceDevice } from './datasources/device-datasource';
import { DatasourceMQTTPublish } from './datasources/publish-datasource';
import { DatasourceMQTTSubscribe } from './datasources/subscribe-datasource';
import { DatasourceTime } from './datasources/time-datasource';
import { DatasourceWeather } from './datasources/weather-datasource';
import { DatasourceDemoDevice } from './datasources/demo-device-datasource';

export class DatasourceFactory {
    static createDatasource(config: DatasourceConfig): Datasource {
        switch (config.type) {
            case DatasourceType.DEVICE:
                return new DatasourceDevice(config);
            case DatasourceType.DEMO_DEVICE:
                return new DatasourceDemoDevice(config);
            case DatasourceType.MQTT_PUBLISHER:
                return new DatasourceMQTTPublish(config);
            case DatasourceType.MQTT_SUBSCRIBER:
                return new DatasourceMQTTSubscribe(config);
            case DatasourceType.WEATHER:
                return new DatasourceWeather(config);
            case DatasourceType.TIME:
                return new DatasourceTime(config);
            default:
                log.error('No instance for this Datasource ', config.type);
        }
    }

    static defaultDatasource(type): Datasource {
        switch (type) {
            case DatasourceType.DEVICE: {
                return DatasourceFactory.createDatasource({
                    name: `${environment?.platformName ?? 'Spoke.Zone'} MQTT`,
                    type,
                    settings: {},
                });
            }
            case DatasourceType.TIME: {
                return DatasourceFactory.createDatasource({
                    name: 'Time',
                    type,
                    settings: { refresh: 1 },
                });
            }
            case DatasourceType.WEATHER: {
                return DatasourceFactory.createDatasource({
                    name: 'Weather',
                    type,
                    settings: {
                        location: '45404',
                        units: 'imperial',
                        refresh: 10,
                        api_key: '7c6a03eab958818d2b4987764d4e7a72',
                    },
                });
            }
        }
        return null;
    }
}
