import { Device } from 'src/app/shared/models/device';

/* Allow the sandbox to interact with devices. */
export class SandboxDevice {
    id: number;
    identifier: string;
    // The data received over MQTT.
    data: any;

    constructor(
        deviceInfo: Device,
        private publishCallback?: (topic: string, message: string) => void
    ) {
        this.id = deviceInfo.id;
        this.identifier = deviceInfo.identifier;
    }

    // Send data to the device.
    send(topic: string, message: string) {
        if (this.publishCallback) {
            this.publishCallback(topic, message);
        }
    }
}
