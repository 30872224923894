import { log } from 'src/app/shared/log';
import { environment } from 'src/environments/environment';
import {
    DatasourceSettingDefinition,
    DatasourceSettingType,
} from './datasource-settings';

export enum DatasourceType {
    DEVICE = 'mrs_mqtt',
    DEMO_DEVICE = 'mrs_demo_mqtt',
    MQTT_PUBLISHER = 'send_mqtt',
    MQTT_SUBSCRIBER = 'paho_mqtt',
    TIME = 'clock',
    WEATHER = 'simpleWeather',
}

export const datasourceTypes = [
    DatasourceType.DEVICE,
    DatasourceType.DEMO_DEVICE,
    DatasourceType.TIME,
    DatasourceType.WEATHER,
    DatasourceType.MQTT_PUBLISHER,
    DatasourceType.MQTT_SUBSCRIBER,
];

export const datasourceTypeOptions = datasourceTypes.map((type) => ({
    name: getDatasourceTypeName(type),
    value: type,
}));

export function getDatasourceTypeName(type: DatasourceType) {
    let datasourceType = '';
    if (environment.isSpokeZone) {
        datasourceType = 'datasource.type.device.spoke_zone';
    } else if (environment.isLenticul) {
        datasourceType = 'datasource.type.device.cloud_connect';
    }

    switch (type) {
        case DatasourceType.DEVICE:
            return datasourceType;
        case DatasourceType.DEMO_DEVICE:
            return 'datasource.type.device.demo';
        case DatasourceType.MQTT_PUBLISHER:
            return 'datasource.type.publisher';
        case DatasourceType.MQTT_SUBSCRIBER:
            return 'datasource.type.subscriber';
        case DatasourceType.TIME:
            return 'label.time';
        case DatasourceType.WEATHER:
            return 'datasource.type.weather';
    }
}

export function getDatasourceSettingDefinitions(
    type: DatasourceType,
): DatasourceSettingDefinition[] {
    switch (type) {
        case DatasourceType.DEVICE:
            return [
                {
                    id: 'device',
                    type: DatasourceSettingType.Device,
                    name: 'device',
                    description: '',
                    defaultValue: 0,
                },
            ];
        case DatasourceType.DEMO_DEVICE:
            return [
                {
                    id: 'device',
                    type: DatasourceSettingType.Device,
                    name: 'datasource.settings.device.demo',
                    description: '',
                    defaultValue: 0,
                },
            ];
        case DatasourceType.MQTT_PUBLISHER:
            return [
                {
                    id: 'server',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.server',
                    description: 'datasource.settings.server.description',
                    defaultValue: environment.mqttUrl,
                },
                {
                    id: 'port',
                    type: DatasourceSettingType.Number,
                    name: 'datasource.settings.port',
                    description: 'datasource.settings.port.description',
                    defaultValue: environment.mqttPort,
                },
                {
                    id: 'use_ssl',
                    type: DatasourceSettingType.Checkbox,
                    name: 'datasource.settings.use_ssl',
                    description: 'datasource.settings.use_ssl.description',
                    defaultValue: false,
                },
                {
                    id: 'username',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.username',
                    description: 'datasource.settings.username.description',
                    defaultValue: '',
                },
                {
                    id: 'password',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.password',
                    description: 'datasource.settings.password.description',
                    defaultValue: '',
                },
                {
                    id: 'topicPrefix',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.topic_prefix',
                    description: 'datasource.settings.topic_prefix.description',
                    defaultValue: '',
                },
                {
                    id: 'topics',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.publish',
                    description: 'datasource.settings.publish.description',
                    defaultValue: '#',
                },
            ];
        case DatasourceType.MQTT_SUBSCRIBER:
            return [
                {
                    id: 'server',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.server',
                    description: 'datasource.settings.server.description',
                    defaultValue: environment.mqttUrl,
                },
                {
                    id: 'port',
                    type: DatasourceSettingType.Number,
                    name: 'datasource.settings.port',
                    description: 'datasource.settings.port.description',
                    defaultValue: environment.mqttPort,
                },
                {
                    id: 'use_ssl',
                    type: DatasourceSettingType.Checkbox,
                    name: 'datasource.settings.use_ssl',
                    description: 'datasource.settings.use_ssl.description',
                    defaultValue: false,
                },
                {
                    id: 'username',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.username',
                    description: 'datasource.settings.username.description',
                    defaultValue: '',
                },
                {
                    id: 'password',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.password',
                    description: 'datasource.settings.password.description',
                    defaultValue: '',
                },
                {
                    id: 'topicPrefix',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.topic_prefix',
                    description: 'datasource.settings.topic_prefix.description',
                    defaultValue: '',
                },
                {
                    id: 'topics',
                    type: DatasourceSettingType.Text,
                    name: 'datasource.settings.subscribe',
                    description: 'datasource.settings.subscribe.description',
                    defaultValue: '#',
                },
            ];
        case DatasourceType.TIME:
            return [
                {
                    id: 'refresh',
                    type: DatasourceSettingType.Number,
                    name: 'datasource.settings.refresh',
                    description:
                        'datasource.settings.refresh.seconds_description',
                    defaultValue: 10,
                },
            ];
        case DatasourceType.WEATHER:
            return [
                {
                    id: 'location',
                    type: DatasourceSettingType.Text,
                    name: 'label.location',
                    description: 'datasource.settings.location.description',
                    defaultValue: '45414',
                },
                {
                    id: 'refresh',
                    type: DatasourceSettingType.Number,
                    name: 'datasource.settings.refresh',
                    description:
                        'datasource.settings.refresh.minutes_description',
                    defaultValue: 10,
                },
                {
                    id: 'useImperialUnits',
                    type: DatasourceSettingType.Checkbox,
                    name: 'datasource.settings.use_imperial_units',
                    description:
                        'datasource.settings.use_imperial_units.description',
                    defaultValue: true,
                },
            ];
        default:
            log.error('No settings for type:', type);
            return [];
    }
}
