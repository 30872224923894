import { filter, map, takeUntil } from 'rxjs/operators';
import { isDefined, isEmpty, setNestedValue } from 'src/app/shared/common';
import { log } from 'src/app/shared/log';
import { CanDatabase } from 'src/app/shared/models/can-database';
import { Device } from 'src/app/shared/models/device';
import { IncomingPacket, MqttClient } from 'src/app/shared/mqtt/mqtt';
import { DataPack } from '../../../shared/data-pack';
import {
    Datasource,
    DatasourceConfig,
    DatasourceConnectContext,
    DatasourceTopicOption,
} from '../datasource';
import { DatasourceType } from '../datasource-type';

export class DatasourceDemoDevice extends Datasource {
    canBuses: Record<string, Array<string | number>> = { names: [], ids: [] };
    canDatabases: CanDatabase[] = [];
    busNames: { [key: string | number]: string | number };
    devices: { [key: string]: Device };
    client: MqttClient;
    interval: NodeJS.Timeout;
    intervalTime = 1000;
    timeStep = this.intervalTime / 1000;
    currentLat = 39.845808121681564;
    currentLon = -84.18879122756341;
    speed = 50;
    bearing = 90;

    get prefix(): string {
        return `mrs/d/${this.settings.device}/`;
    }

    constructor(config: DatasourceConfig) {
        super(DatasourceType.DEMO_DEVICE, config);
        this.settings.device = +this.settings.device;
    }

    disconnect() {
        super.disconnect();
        this.disconnectClient();
    }

    build(): DatasourceConfig {
        return {
            uuid: this.uuid,
            name: this.name,
            type: this.type,
            settings: { device: this.settings.device },
        };
    }

    async connect(context: DatasourceConnectContext) {
        this.busNames = context.busNames;
        this.devices = context.devices;
        const deviceId = this.settings.device;
        const device = this.devices[deviceId];
        if (!isDefined(device)) {
            log.tag('DemoDeviceDatasource').error(
                'Can not connect demo device datasource. No such demo device found in the devices list. Demo Device ID:',
                deviceId,
                'Devices:',
                this.devices,
            );
            return;
        }

        this.createDatasourcePath(context);
        await this.connectMqtt();

        this.interval = setInterval(() => {
            this.broadcastLocation();
            this.broadcastCanData();
        }, this.intervalTime); // TODO: we can take input from the user
    }

    // TODO: this MAJORLY needs replaced!
    async createDatasourcePath(context: DatasourceConnectContext) {
        const { devices, canDatabases } = context;
        const device = devices[this.settings.device];

        this.canDatabases = canDatabases.filter(
            (db) => db.modelId == device.modelId,
        );

        this.canDatabases.forEach((db: CanDatabase) => {
            db.messages.forEach((m) => {
                // save can_ids
                if (m.canId || m.canId === 0) {
                    this.canBuses.names[m.canId] = m.descriptor;
                    this.canBuses.ids[m.descriptor] = m.canId;
                }
                this.topicOptions.push(
                    ...m.signals.map<DatasourceTopicOption>((signal) => ({
                        direction: 'subscribe',
                        name: signal.name,
                        group: m.descriptor,
                        topicSegments: [
                            'mon',
                            db.busType,
                            m.descriptor,
                            signal.name,
                        ],
                    })),
                );
            });
        });
    }

    async connectMqtt() {
        const deviceId = this.settings.device;
        if (!deviceId) {
            return [];
        }
        this.disconnectClient();
        this.client = new MqttClient();
        await this.client?.connect();
        this.client
            ?.observe(`mrs/d/${deviceId}/mon/#`)
            .pipe(
                takeUntil(this.disconnect$),
                filter((packet) => !!packet),
                filter(({ retain }) => !retain),
                map((packet) => this.parsePacket(packet)),
                filter((data) => data && !isEmpty(data)),
            )
            .subscribe((data) => this.data$.next(data));
    }

    /**
     * Disconnect the internal MQTT client, if it exists.
     */
    disconnectClient() {
        if (this.client) {
            this.client.disconnect();
            delete this.client;
        }
        clearInterval(this.interval);
    }

    private parsePacket(packet: IncomingPacket) {
        // for selection like mon/xxx | ctrl/xxx ....
        let key = packet.topic.replace(this.prefix, '');
        if (!key.startsWith('mon')) {
            return;
        }

        let rawValue;
        const parsedData = this.parseCanData(packet);
        if (parsedData && !isEmpty(parsedData)) {
            // Handle CAN data
            key = Object.keys(parsedData)[0];
            rawValue = parsedData[key];
        } else {
            // Decode the string from the payload.
            rawValue = new TextDecoder().decode(packet.payload);
        }

        let value;
        try {
            // try to parse JSON
            value = JSON.parse(rawValue);
        } catch {
            value = rawValue;
        }
        if (value == null || value == undefined) {
            // Invalid value, so don't do anything with it.
            return;
        }

        return { [key]: value };
    }

    private parseCanData({ topic, payload }: IncomingPacket) {
        // If we're going to try to extract CAN data, it needs to match the MRS topic format
        // We can accept the following topic formats:
        // mrs/d/1234/mon/0/3fa4567
        // mrs/d/1234/mon/can1/568732
        const regex =
            /mrs\/d\/([0-9]*)\/(mon|ctrl)\/([a-zA-Z]*)([0-9]*)\/([a-fA-F0-9]*)/i;
        const matches = regex.exec(topic);
        // If we didn't get a match or if the length is not 6, then the topic is invalid
        if (matches === null || matches.length !== 6) {
            return;
        }

        const action = matches[2];
        const busId = parseInt(matches[4]);
        const rawCanId = matches[5];
        let canId: number | null = null;
        // The CAN ID may be in hex format, so let's check and convert it to integer
        if (isNaN(+rawCanId)) {
            canId = parseInt(rawCanId, 16);
        } else {
            canId = +rawCanId;
        }
        if (canId == null) {
            return;
        }

        // Find the messages for all applicable CAN databases
        const messages = this.canDatabases
            .filter((db) => db.busTypeId == busId)
            .map((db) => db.messages)
            .flat(1);
        if (messages.length == 0) {
            return;
        }
        // Find the CAN message
        const canMessage = messages.find((m) => m.canId == canId);
        if (!canMessage) {
            return;
        }
        const messageValues = DataPack.decode(payload, canMessage);
        const data = {};
        const busName = this.busNames[busId].toString();
        const path = [action, busName, canMessage.descriptor];
        setNestedValue(data, path, messageValues);
        return data;
    }

    private broadcastLocation() {
        const distance = this.speed * this.timeStep;
        const { lat, lon } = this.getNextGPSCoordinate(
            this.currentLat,
            this.currentLon,
            distance,
            this.bearing,
        );

        this.client.publish({
            topic: `${this.prefix}mon/location`,
            text: JSON.stringify({ lat, lon }),
        });
    }

    private toRadians(degrees: number): number {
        return degrees * (Math.PI / 180);
    }

    private toDegrees(radians: number): number {
        return radians * (180 / Math.PI);
    }

    private getNextGPSCoordinate(
        lat: number,
        lon: number,
        distance: number,
        bearing: number,
    ): { lat: number; lon: number } {
        const R = 6371e3;
        const angularDistance = distance / R;
        const bearingRad = this.toRadians(bearing);
        const latRad = this.toRadians(lat);
        const lonRad = this.toRadians(lon);
        const newLatRad = Math.asin(
            Math.sin(latRad) * Math.cos(angularDistance) +
                Math.cos(latRad) *
                    Math.sin(angularDistance) *
                    Math.cos(bearingRad),
        );
        const newLonRad =
            lonRad +
            Math.atan2(
                Math.sin(bearingRad) *
                    Math.sin(angularDistance) *
                    Math.cos(latRad),
                Math.cos(angularDistance) -
                    Math.sin(latRad) * Math.sin(newLatRad),
            );

        lat = this.toDegrees(newLatRad);
        lon = this.toDegrees(newLonRad);

        this.currentLat = lat;
        this.currentLon = lon;

        return { lat, lon };
    }

    private broadcastCanData() {
        this.canDatabases.forEach((db) => {
            db.messages.forEach((m) => {
                const values: Record<string, number> = {};
                for (const signal of m.signals) {
                    values[signal.name] = this.getRandomNumber(
                        signal.minimum,
                        signal.maximum,
                    );
                }
                const payload = DataPack.encode(values, m);
                const canDatabase = this.canDatabases.find(
                    (f) => f.id === m.databaseId,
                );
                const topic = `${this.prefix}mon/${canDatabase.busTypeId}/${m.canId}`;
                this.client.publish({ topic, payload });
            });
        });
    }

    private getRandomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
