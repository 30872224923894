import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { titleCase } from 'src/app/shared/common';
import {
    Datasource,
    DatasourceConfig,
    DatasourceConnectContext,
} from '../datasource';
import { DatasourceType } from '../datasource-type';

export class DatasourceWeather extends Datasource {
    constructor(config: DatasourceConfig) {
        super(DatasourceType.WEATHER, config);
        this.topicOptions = [
            {
                direction: 'subscribe',
                name: 'datasource.weather.conditions',
                group: 'datasource.type.weather',
                topicSegments: ['conditions'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.current_temperature',
                group: 'datasource.type.weather',
                topicSegments: ['current_temp'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.feels_like',
                group: 'datasource.type.weather',
                topicSegments: ['feels_like'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.high_temperature',
                group: 'datasource.type.weather',
                topicSegments: ['high_temp'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.low_temperature',
                group: 'datasource.type.weather',
                topicSegments: ['low_temp'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.pressure',
                group: 'datasource.type.weather',
                topicSegments: ['pressure'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.humidity',
                group: 'datasource.type.weather',
                topicSegments: ['humidity'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.wind_speed',
                group: 'datasource.type.weather',
                topicSegments: ['wind_speed'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.wind_direction',
                group: 'datasource.type.weather',
                topicSegments: ['wind_direction'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.place_name',
                group: 'label.location',
                topicSegments: ['place_name'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.sunrise',
                group: 'label.location',
                topicSegments: ['sunrise'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.weather.sunset',
                group: 'label.location',
                topicSegments: ['sunset'],
            },
        ];
    }

    async connect(context: DatasourceConnectContext) {
        // send first request when init/update
        this.sendWeatherRequest(context.httpClient);
        // send repeated requests
        interval(this.settings.refresh * 60 * 1000)
            .pipe(takeUntil(this.disconnect$))
            .subscribe(() => this.sendWeatherRequest(context.httpClient));
    }

    private sendWeatherRequest(httpClient: HttpClient) {
        const url = `https://api.openweathermap.org/data/2.5/weather?APPID=7c6a03eab958818d2b4987764d4e7a72&units=${
            this.settings.useImperialUnits ? 'imperial' : 'metric'
        }&zip=${encodeURIComponent(this.settings.location)}`;
        httpClient.get(url).subscribe((data: any) =>
            this.data$.next({
                place_name: data.name,
                sunrise: new Date(data.sys.sunrise * 1000).toLocaleTimeString(),
                sunset: new Date(data.sys.sunset * 1000).toLocaleTimeString(),
                conditions: titleCase(data.weather[0].description),
                current_temp: data.main.temp,
                feels_like: data.main.feels_like,
                high_temp: data.main.temp_max,
                low_temp: data.main.temp_min,
                pressure: data.main.pressure,
                humidity: data.main.humidity,
                wind_speed: data.wind.speed,
                wind_direction: data.wind.deg,
            })
        );
    }
}
