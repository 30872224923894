import { BehaviorSubject, interval, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { log } from 'src/app/shared/log';
import {
    DashboardStatus,
    getDashboardStatus,
} from 'src/app/shared/models/dashboard';
import { SandboxDevice } from './device';

// Datasource objects that get sent into the sandbox.
export class SandboxDatasource {
    private _callbacks = [];
    lastUpdated$ = new BehaviorSubject(0);
    status$ = merge(this.lastUpdated$, interval(1000)).pipe(
        map(() => this.status),
    );

    get lastUpdated() {
        return this.lastUpdated$.value;
    }

    get status() {
        return getDashboardStatus(+new Date() - this.lastUpdated);
    }

    get isLive() {
        return this.status == DashboardStatus.LIVE;
    }

    constructor(
        public name: string,
        public device?: SandboxDevice,
    ) {}

    addListener(callback) {
        this._callbacks.push(callback);
    }

    newData(values) {
        this.lastUpdated$.next(+new Date());
        this._callbacks.forEach((callback) => callback(values));
    }

    resetLastUpdated() {
        this.lastUpdated$.next(0);
    }
}
